export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_MESSAGES = "GET_MESSAGES";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const GET_SNACK_MESSAGES = "GET_SNACK_MESSAGES";
export const CLEAR_SNACK_MESSAGES = "CLEAR_SNACK_MESSAGES";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USERS = "GET_USERS";
export const SEARCH_USERS_BY_NAME = "SEARCH_USERS_BY_NAME";
export const UPDATE_DATE_RANGES = "UPDATE_DATE_RANGES";
export const USER_FILTER = "USER_FILTER";
export const UPDATE_USER_IDS = "UPDATE_USER_IDS";
export const GET_USERS_OPTIONS = "GET_USERS_OPTIONS";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_DOCUMENTS = "GET_USER_DOCUMENTS";
export const USER_2FA_UPDATE = "USER_2FA_UPDATE";
export const VERIFY_2FA_CODE = "VERIFY_2FA_CODE";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const GET_USER_IDENTITY = "GET_USER_IDENTITY";
export const GET_SUMSUB_VERIFICATION_TOKEN = "GET_SUMSUB_VERIFICATION_TOKEN";
export const SUMSUB_VERIFICATION_COMPLETED = "SUMSUB_VERIFICATION_COMPLETED";
export const STORE_USER_DOCUMENT = "STORE_USER_DOCUMENT";
export const GET_USER_RESIDENSE = "GET_USER_RESIDENSE";
export const STORE_USER_RESIDENSE = "STORE_USER_RESIDENSE";
export const GET_USER_LOGS = "GET_USER_LOGS";
export const GET_USER_ANNOUNCEMENT = "GET_USER_ANNOUNCEMENT";
export const SUBMIT_IDENTITY_FORM = "SUBMIT_IDENTITY_FORM";
export const GET_AVAILABLE_MARKETS = "GET_AVAILABLE_MARKETS";
export const ACTIVE_MARKET = "ACTIVE_MARKET";
export const ACTIVE_FUTURE_MARKET = "ACTIVE_FUTURE_MARKET";
export const USER_2FA_STATUS = "USER_2FA_STATUS";

export const GET_USER_PERSONAL_INFO = "GET_USER_PERSONAL_INFO";
export const SAVE_USER_PERSONAL_INFO = "SAVE_USER_PERSONAL_INFO";
export const GET_USER_BANK_INFO = "GET_USER_BANK_INFO";
export const SAVE_USER_BANK_INFO = "SAVE_USER_BANK_INFO";

export const STORE_CORPORATE_INFO = "STORE_CORPORATE_INFO";

export const GET_USER_WALLET = "GET_USER_WALLET";
export const GENERATE_WALLET_ADDRESS = "GENERATE_WALLET_ADDRESS";
export const GET_WALLET_ADDRESSES = "GET_WALLET_ADDRESSES";
export const GET_ACTIVE_ASSETS = "GET_ACTIVE_ASSETS";
export const GET_DEPOSIT_REQUESTS = "GET_DEPOSIT_REQUESTS";
export const GET_WITHDRAWAL_REQUESTS = "GET_WITHDRAWAL_REQUESTS";
export const SEND_CRYPTO = "SEND_CRYPTO";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_ACTIVE_ASSETS_LIST = "GET_ACTIVE_ASSETS_LIST";

export const GET_USER_TRADE_ORDERS = "GET_USER_TRADE_ORDERS";
export const GET_USER_MARKET_DEALS = "GET_USER_MARKET_DEALS";
export const GET_USER_SELL_ORDER_BOOK = "GET_USER_SELL_ORDER_BOOK";
export const GET_USER_BUY_ORDER_BOOK = "GET_USER_BUY_ORDER_BOOK";
export const GET_USER_ORDER_DEPTH = "GET_USER_ORDER_DEPTH";
export const GET_PENDING_ORDERS = "GET_PENDING_ORDERS";

export const GET_REFERRAL = "GET_REFERRAL";
export const GET_REFERRAL_TREE = "GET_REFERRAL_TREE";
export const GET_REFERRAL_EARNED = "GET_REFERRAL_EARNED";
export const GET_USER_DETAILS = "GET_USER_DETAILS";

export const GET_USER_API_KEYS = "GET_USER_API_KEYS";

export const GET_MARKET_LAST = "GET_MARKET_LAST";
export const GET_FINAL_MARKET_LAST = "GET_FINAL_MARKET_LAST";
export const GET_MARKET_STATUS_TODAY = "GET_MARKET_STATUS_TODAY";
export const GET_TRADING_CHART_DATA = "GET_TRADING_CHART_DATA";
export const GET_AED_PRICE = "GET_AED_PRICE";

export const GET_AGENT_CLIENTS = "GET_AGENT_CLIENTS";
export const GET_USER_CLIENT_PROFILE = "GET_USER_CLIENT_PROFILE";
export const GET_USER_CLIENT_DOCUMENTS = "GET_USER_CLIENT_DOCUMENTS";
export const GET_USER_CLIENT_IDENTITY = "GET_USER_CLIENT_IDENTITY";
export const UPLOAD_CLIENT_DOCUMENT = "UPLOAD_CLIENT_DOCUMENT";
export const GET_USER_CLIENT_RESIDENSE = "GET_USER_CLIENT_RESIDENSE";
export const GET_USER_CLIENT_PERSONAL_INFO = "GET_USER_CLIENT_PERSONAL_INFO";
export const SAVE_USER_CLIENT_PERSONAL_INFO = "SAVE_USER_CLIENT_PERSONAL_INFO";
export const GET_USER_CLIENT_BANK_INFO = "GET_USER_CLIENT_BANK_INFO";
export const SAVE_USER_CLIENT_BANK_INFO = "SAVE_USER_CLIENT_BANK_INFO";
export const GET_USER_CLIENT_LOGS = "GET_USER_CLIENT_LOGS";
export const GET_CLIENT_TRANSACTIONS = "GET_CLIENT_TRANSACTIONS";
export const GET_CLIENT_USER_PROFILE = "GET_CLIENT_USER_PROFILE";
export const GET_USER_CLIENT_WALLETS = "GET_USER_CLIENT_WALLETS";
export const GET_AGENT_DEFAULT_SETTINGS = "GET_AGENT_DEFAULT_SETTINGS";
export const GET_AGENT_COMMISSIONS = "GET_AGENT_COMMISSIONS";
export const GET_CLIENT_PENDING_ORDERS = "GET_CLIENT_PENDING_ORDERS";
export const GET_CLIENT_TRADE_ORDERS = "GET_CLIENT_TRADE_ORDERS";
export const GET_CLIENT_COMMISSIONS = "GET_CLIENT_COMMISSIONS";

export const GET_ACTIVE_MARGIN_ASSETS = "GET_ACTIVE_MARGIN_ASSETS";
export const GET_USER_MARGIN_LEVEL = "GET_USER_MARGIN_LEVEL";

export const EXPAND_NAV_BAR = "EXPAND_NAV_BAR";

export const GET_MY_LOCATION = "GET_MY_LOCATION";

export const GET_FUTURE_TICKERS = "GET_FUTURE_TICKERS";

export const GET_AED_TRADING_LEVELS = "GET_AED_TRADING_LEVELS";
export const GET_INR_TRADE_LEVELS = "GET_INR_TRADE_LEVELS";
export const GET_USD_TRADE_LEVELS = "GET_USD_TRADE_LEVELS";
export const GET_AGENT_TRADE_LEVELS = "GET_AGENT_TRADE_LEVELS";
export const GET_SUB_AGENT_TRADE_LEVELS = "GET_AGENT_TRADE_LEVELS";
export const SEND_RESET_PASSWORD_LINK = "SEND_RESET_PASSWORD_LINK";
export const SEND_RESET_PASSWORD_LINK_UNSET = "SEND_RESET_PASSWORD_LINK_UNSET";
export const SET_VERIFY_EMAIL = "SET_VERIFY_EMAIL";
export const USER_PHONE_UPDATE = "USER_PHONE_UPDATE";
export const GET_USDT_INR_PRICE = "GET_USDT_INR_PRICE";
export const GET_BTX_MARKET_DATA = "GET_BTX_MARKET_DATA";
export const GET_BTX_AED_MARKET_DATA = "GET_BTX_AED_MARKET_DATA";

export const GET_ACTIVE_BITEX_SAVING_PLAN = "GET_ACTIVE_BITEX_SAVING_PLAN";
export const GET_ACTIVE_BITEX_SAVING_USER_WALLET =
  "GET_ACTIVE_BITEX_SAVING_USER_WALLET";
export const GET_BITEX_TRANSFER_HISTORY = "GET_BITEX_TRANSFER_HISTORY";
export const GET_BITEX_LEND_AMOUNT = "GET_BITEX_LEND_AMOUNT";

export const SET_LAST_LOGIN_REDIRECTED_LINK = "SET_LAST_LOGIN_REDIRECTED_LINK";
export const SET_LOGIN_USER = "SET_LOGIN_USER";

export const GET_REFFERAL_SEETING = "GET_REFFERAL_SEETING";
export const GET_WALLET_MAINTENANCE = "GET_WALLET_MAINTENANCE";
export const GET_TRADING_MAINTENANCE = "GET_TRADING_MAINTENANCE";
export const GET_MAINTENANCE = "GET_MAINTENANCE";
export const GET_BANK_DETAIL = "GET_BANK_DETAIL";
